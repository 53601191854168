import { render, staticRenderFns } from "./page.vue?vue&type=template&id=65de15e2&scoped=true&"
import script from "./page.vue?vue&type=script&lang=js&"
export * from "./page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../MultiSiteClient/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65de15e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtDynamic: require('E:/JenkinsPyWorkspace/Jobs/IntegrationsDeploy/Projects/CPv3/MultiSiteClient/node_modules/@blokwise/dynamic/src/components/Dynamic.vue').default})
